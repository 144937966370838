<!-- 注册弹窗 -->
<template>
  <div>
    <el-dialog
      class="loginDiaBox"
      :before-close="setShowData"
      :visible.sync="!!$store.state.showRegisterDialog"
    >
      <div class="loginBox dip fdcolmun">
        <router-link to="/" class="logoBox">
          <img
            class="logoImg"
            :src="require('@/assets/hometop/logo-rakumart-europe.svg')"
            alt=""
          />
        </router-link>

        <div class="loginTitleFont">
          <h2>{{ $fanyi("在Rakumart注册") }}</h2>
          <h3>{{ $fanyi("输入您的电子邮件开始在Rakumart Espana注册。") }}</h3>
        </div>

        <div>
          <el-form
            :model="loginData"
            :rules="loginRules"
            ref="loginForm"
            class="demo-ruleForm"
          >
            <el-form-item prop="login_name">
              <div class="fromName">
                <div class="placeholder">E-mail</div>
              </div>
              <el-input
                v-model="loginData.login_name"
                :placeholder="$fanyi('写你的电子邮件')"
              ></el-input>
            </el-form-item>
          </el-form>
        </div>

        <button class="bgBlueBtn loginBtn" @click="goregister()">
          {{ $fanyi("注册") }}
        </button>

        <div class="goRegisterBox">
          {{ $fanyi("你有一个帐户吗？") }}
          <b
            @click="
              $store.commit('getshowRegisterDialog', false);
              $store.commit('getshowLoginDialog', true);
            "
            >{{ $fanyi("去登录") }}</b
          >
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      loginData: {},
      loginRules: {
        login_name: [
          // {
          //   required: true,
          //   message: this.$fanyi("此字段为必填项"),
          //   trigger: "blur",
          // },
          // {
          //   type: "email",
          //   message: this.$fanyi("请输入正确的邮箱地址"),
          //   trigger: ["blur", "change"],
          // },
        ],
        password: [
          {
            required: true,
            message: this.$fanyi("请输入密码"),
            trigger: "blur",
          },
        ],
      },
    };
  },
  components: {},
  computed: {},
  created() {},
  methods: {
    setShowData() {
      this.$store.commit("getshowRegisterDialog", false);
    },
    goregister() {
      this.$store.commit("getshowRegisterDialog", false);
      this.$fun.routerToPage("/register?email=" + this.loginData.login_name);
    },
  },
};
</script>
<style lang="scss" scoped="scoped">
@import "@/css/mixin.scss";
.loginDiaBox {
  /deep/.el-dialog {
    width: max-content;
    border-radius: 16px;
    overflow: hidden;
  }
  /deep/.el-dialog__header {
    // display: none;
    padding: 0;
  }
  /deep/.el-dialog__body {
    padding: 0;
  }
  /deep/.el-dialog__close {
    display: none;
  }
}
.loginBox {
  width: 471px;
  padding: 32px 24px;
  border-radius: 16px;
  border: 1.5px solid var(--Gray-200, #eaecf0);
  background: #fff;

  /* Shadows/shadow-2xl */
  box-shadow: 0px 24px 48px -12px rgba(16, 24, 40, 0.18);
  .logoImg {
    width: 168px;
    height: 45.405px;
    margin-bottom: 24px;
  }

  .loginTitleFont {
    margin-bottom: 24px;
    text-align: center;
    h2 {
      color: var(--Gray-950, #0c111d);
      margin-bottom: 8px;
      font-size: 18px;
      font-weight: 700;
      line-height: 28px; /* 155.556% */
    }
    h3 {
      color: var(--Gray-500, #667085);
      text-align: center;

      font-size: 16px;
      line-height: 24px; /* 150% */
    }
  }

  .demo-ruleForm {
    width: 423px;
    margin-bottom: 24px;
    /deep/.el-form-item__content {
      height: 65px;
    }
    /deep/.placeholder {
      color: var(--Gray-700, #344054);
      font-size: 14px;
      font-weight: 600;
      line-height: 20px; /* 142.857% */
      margin-bottom: 8px;
      position: relative;
    }
    /deep/.el-input__inner {
      padding: 0px 16px;
      gap: 8px;
      height: 40px;
      border-radius: 8px;
      border: 1px solid var(--Gray-200, #eaecf0);
      background: #fff;

      font-size: 16px;
      line-height: 24px; /* 150% */
      &::placeholder {
        color: var(--Gray-300, #d0d5dd);
      }
    }
    .forgotPasswordBox {
      color: var(--Rakumart-500, #1a73e8);
      font-size: 12px;
      font-weight: 600;
      line-height: 18px; /* 150% */
      position: absolute;
      top: 0;
      right: 0;
      cursor: pointer;
    }
    .showPaddwordBox {
      cursor: pointer;
      position: absolute;
      top: 34px;
      right: 16px;
    }
  }
  .rememberPassword {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 24px;
    .el-switch {
      margin-right: 16px;
      /deep/.el-switch__input {
        display: none;
      }
    }
  }
  .loginBtn {
    width: 100%;
    padding: 12px 18px;
    border-radius: 8px;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px; /* 150% */
    margin-bottom: 24px;
  }
  .goRegisterBox {
    color: var(--Gray-700, #344054);
    font-size: 14px;
    line-height: 20px; /* 142.857% */
    b {
      color: var(--Rakumart-500, #1a73e8);
      font-weight: 600;
      cursor: pointer;
    }
  }
}
</style>
