import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ElementUI from "element-ui";
// import 'element-ui/lib/theme-chalk/index.css';
import "./css/reset.css";
import "./css/theme.scss";
import "./css/common.css";
import "./utlis/directive"; //自定义指令
import "./css/font/font.css";
import "./css/openSans.css";
import "./css/public.scss";
import "./css/animate.min.css";

// 自定义消息弹出框
import mss from "@/components/message/index.vue";
Vue.prototype.$Mmsg = mss.methods.Mmsg;

// 全局数据放到imdata下
import imdata from "./utlis/allImportData";
Vue.prototype.$imdata = imdata;
// 事件挂到fun下
import fun from "./utlis/CommonlyUsedFunctions";
Vue.prototype.$fun = fun;
import language from "./utlis/language";
Vue.prototype.$fanyi = language;
// API挂到vue原型下
import api from "./api/apis";
Vue.prototype.$api = api;
import locale from "element-ui/lib/locale/lang/es";
Vue.use(ElementUI, {
  locale,
});
Vue.config.productionTip = false;

// Vue.prototype.$bus = new Vue(); // event Bus 用于无关系组件间的通信。
import VueBus from "vue-bus";
Vue.use(VueBus);
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
